import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RegistrationForm from "./pages/RegistrationForm";

import "./App.css";

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<RegistrationForm />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
