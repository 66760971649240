/* eslint-disable sort-keys-fix/sort-keys-fix */
//import axios from 'axios'
import _ from "lodash";
import moment from "moment";
import {
	// useEffect,
	useState
} from "react";
// import "react-tippy/dist/tippy.css";

// import { Tooltip } from "react-tippy";


import { newRegistration, getRegistration, renewRegistration, amendRegistration } from "../../api/ample";
import ErrorModal from "../../components/ErrorModal";
import RegistrationLayout from "../../layout/RegistrationLayout";


const RegistrationForm = () => {
	const [formData, setFormData] = useState({

		registration_type: "",
		client_id: "",
		password: "",
		submitter_type: "",

		patient_first_name: "",
		patient_middle_name: "",
		patient_last_name: "",
		patient_date_of_birth: "",
		patient_gender: "",
		patient_main_phone: "",
		patient_alt_phone: "",
		patient_email: "",
		patient_vet_status: "",
		patient_vet_knumber: "",
		patient_primary_address_type: "",
		patient_primary_address_street: "",
		patient_primary_address_unit: "",
		// patient_primary_address_institution_name: "",
		patient_primary_address_city: "",
		patient_primary_address_province: "",
		patient_primary_address_postal: "",
		patient_mailing_address_same_as_primary: true,
		patient_mailing_address_po_box: "",
		patient_mailing_address_street: "",
		patient_mailing_address_unit: "",
		// patient_mailing_address_institution_name: "",
		patient_mailing_address_city: "",
		patient_mailing_address_province: "",
		patient_mailing_address_postal: "",
		// institution_address_street: "",
		// institution_address_unit: "",
		// institution_address_name: "",
		// institution_address_city: "",
		// institution_address_province: "",
		// institution_address_postal: "",
		// institution_main_phone: "",
		// institution_alt_phone: "",
		// institution_email: "",
		// institution_manager_name: "",
		// institution_manager_signature: "",
		// caregiver1_first_name: "",
		// caregiver1_last_name: "",
		// caregiver1_date_of_birth: "",
		// caregiver1_main_phone: "",
		// caregiver1_alt_phone: "",
		// caregiver1_email: "",
		// caregiver1_signature: "",
		// caregiver1_sign_date: "",
		// caregiver2_first_name: "",
		// caregiver2_last_name: "",
		// caregiver2_date_of_birth: "",
		// caregiver2_main_phone: "",
		// caregiver2_alt_phone: "",
		// caregiver2_email: "",
		// caregiver2_signature: "",
		// caregiver2_sign_date: "",
		attestation_accepted: false,
		// signature: "",
		// sign_date: "",
	});

	const [formFieldErrors, setFormFieldErrors] = useState({
		registration_type: false,
		client_id: false,
		password: false,
		submitter_type: false,
		patient_first_name: false,
		patient_middle_name: false,
		patient_last_name: false,
		patient_date_of_birth: false,
		patient_gender: false,
		patient_main_phone: false,
		patient_alt_phone: false,
		patient_email: false,
		patient_vet_status: false,
		patient_vet_knumber: false,
		patient_primary_address_type: false,
		patient_primary_address_street: false,
		patient_primary_address_unit: false,
		patient_primary_address_city: false,
		patient_primary_address_province: false,
		patient_primary_address_postal: false,
		patient_mailing_address_same_as_primary: false,
		patient_mailing_address_po_box: false,
		patient_mailing_address_street: false,
		patient_mailing_address_unit: false,
		patient_mailing_address_city: false,
		patient_mailing_address_province: false,
		patient_mailing_address_postal: false,
		attestation_accepted: false,
	});

	const [showErrorModal, setShowErrorModal] = useState(false);
	const [regSubmittedSuccessfully, setRegSubmittedSuccessfully] = useState(false);
	const [errorMessage, setErrorMessage] = useState("false");
	const [isLoading, setIsLoading] = useState(false);
	const [hasCurrentReg, setHasCurrentReg] = useState(false);



	// Validate Form Fields
	const phoneRegex = /\d{3}-\d{3}-\d{4}/;
	const kNumberRegex = /\d{7}/;
	const postalRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
	const dateRegex = /\d{4}-\d{2}-\d{2}/;
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const clientIdRegex = /\d{4}-\d{4}-\d{4}-\d{4}/;

	const isEmpty = (val: string) => {
		return val === "" || val === undefined;
	};
	const isIn = (val: string, array: Array<string>) => {
		return _.indexOf(array, val) >= 0
	};

	const validateFormField = (fieldName: string, fieldValue: any = undefined) => {

		if (_.isUndefined(fieldValue))
			fieldValue = formData[fieldName as keyof typeof formData];

		switch (fieldName) {

			case "registration_type": return !isIn(fieldValue, ["New", "Renewal", "Amendment"]);
			case "client_id": return isIn(formData.registration_type, ["Renewal", "Amendment"]) ? !clientIdRegex.test(fieldValue) : false;
			case "password": return isIn(formData.registration_type, ["Renewal", "Amendment"]) ? isEmpty(fieldValue) : false;
			case "submitter_type": return !isIn(fieldValue, ["Applicant", "Caregiver"]);

			case "patient_first_name": return isEmpty(fieldValue);
			case "patient_middle_name": return false;
			case "patient_last_name": return isEmpty(fieldValue);
			case "patient_date_of_birth": return !dateRegex.test(fieldValue) || _.isNaN(moment(fieldValue).get("year")) || moment(fieldValue).isAfter(moment());
			case "patient_gender": return !isIn(formData.patient_gender, ["Male", "Female", "Other", "Undisclosed"]);
			case "patient_main_phone": return !phoneRegex.test(fieldValue);
			case "patient_alt_phone": return !isEmpty(fieldValue) && !phoneRegex.test(fieldValue);
			case "patient_email": return !emailRegex.test(fieldValue);
			case "patient_vet_status": return fieldValue !== "" && !isIn(fieldValue, ["Still Serving", "Non-Serving"]);
			case "patient_vet_knumber": return !isEmpty(fieldValue) && !kNumberRegex.test(fieldValue);
			case "patient_primary_address_type": return !isIn(fieldValue, ["Private Home", "Shelter", "Hostel", "Group Home", "Nursing/Care Home"]);
			case "patient_primary_address_street": return isEmpty(fieldValue);
			case "patient_primary_address_unit": return false;
			case "patient_primary_address_city": return isEmpty(fieldValue);
			case "patient_primary_address_province": return !isIn(fieldValue, ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "SK", "QC", "YT"]);
			case "patient_primary_address_postal": return !postalRegex.test(fieldValue);
			case "patient_mailing_address_same_as_primary": return false;
			case "patient_mailing_address_po_box": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : false;
			case "patient_mailing_address_street": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : false;
			case "patient_mailing_address_unit": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : false;
			case "patient_mailing_address_city": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : isEmpty(fieldValue);
			case "patient_mailing_address_province": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : !isIn(fieldValue, ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "SK", "QC", "YT"]);
			case "patient_mailing_address_postal": return formData?.patient_mailing_address_same_as_primary == true ? !isEmpty(fieldValue) : !postalRegex.test(fieldValue);
			case "attestation_accepted": return formData?.attestation_accepted !== true;
		};

		return true;
	};

	const validateForm = () => {
		const validateFormFields = {
			registration_type: validateFormField("registration_type"),
			client_id: validateFormField("client_id"),
			password: validateFormField("password"),
			submitter_type: validateFormField("submitter_type"),

			patient_first_name: validateFormField("patient_first_name"),
			patient_middle_name: validateFormField("patient_middle_name"),
			patient_last_name: validateFormField("patient_last_name"),
			patient_date_of_birth: validateFormField("patient_date_of_birth"),
			patient_gender: validateFormField("patient_gender"),
			patient_main_phone: validateFormField("patient_main_phone"),
			patient_alt_phone: validateFormField("patient_alt_phone"),
			patient_email: validateFormField("patient_email"),
			patient_vet_status: validateFormField("patient_vet_status"),
			patient_vet_knumber: validateFormField("patient_vet_knumber"),
			patient_primary_address_type: validateFormField("patient_primary_address_type"),
			patient_primary_address_street: validateFormField("patient_primary_address_street"),
			patient_primary_address_unit: validateFormField("patient_primary_address_unit"),
			patient_primary_address_city: validateFormField("patient_primary_address_city"),
			patient_primary_address_province: validateFormField("patient_primary_address_province"),
			patient_primary_address_postal: validateFormField("patient_primary_address_postal"),
			patient_mailing_address_same_as_primary: validateFormField("patient_mailing_address_same_as_primary"),
			patient_mailing_address_po_box: validateFormField("patient_mailing_address_po_box"),
			patient_mailing_address_street: validateFormField("patient_mailing_address_street"),
			patient_mailing_address_unit: validateFormField("patient_mailing_address_unit"),
			patient_mailing_address_city: validateFormField("patient_mailing_address_city"),
			patient_mailing_address_province: validateFormField("patient_mailing_address_province"),
			patient_mailing_address_postal: validateFormField("patient_mailing_address_postal"),
			attestation_accepted: validateFormField("attestation_accepted"),
		};
		setFormFieldErrors(validateFormFields);
		if (Object.values(validateFormFields).includes(true)) {
			return;
		}

		createRegistration();
	};
	const createRegistration = async () => {

		try {
			setIsLoading(true);
			const data: any = { ...formData };

			let res;
			if (formData.registration_type == "New") {
				res = await newRegistration(data)
			}
			else if (formData.registration_type == "Renewal") {
				res = await renewRegistration(data)
			}
			else if (formData.registration_type == "Amendment") {
				res = await amendRegistration(data)
			}
			setIsLoading(false);
			if (res) {
				setRegSubmittedSuccessfully(true);
			}
		} catch (error: any) {
			setIsLoading(false);
			setShowErrorModal(true);
			setErrorMessage(
				error.response?.data ||
				"There was an error, please try again later or contact support at ClientCare@noblegrowthcorp.com."
			);
		}
	};
	const getCurrentRegistration = async () => {
		try {
			if (!clientIdRegex.test(formData.client_id) || !formData.password) {

				setFormFieldErrors((prevState) => ({
					...prevState,
					["client_id"]: validateFormField("client_id"),
					["password"]: validateFormField("password"),
				}));
				return;
			}

			setIsLoading(true);
			const data = await getRegistration(formData.client_id, formData.password);
			setIsLoading(false);

			if (data) {

				setFormItemValue("patient_first_name", data?.registration?.first_name ? data?.registration?.first_name : "");
				setFormItemValue("patient_middle_name", data?.registration?.middle_name ? data?.registration?.middle_name : "");
				setFormItemValue("patient_last_name", data?.registration?.last_name ? data?.registration?.last_name : "");
				setFormItemValue("patient_date_of_birth", data?.registration?.date_of_birth ? data?.registration?.date_of_birth : "");
				setFormItemValue("patient_gender", data?.registration?.gender ? data?.registration?.gender : "");
				setFormItemValue("patient_main_phone", data?.registration?.telephone_1 ? data?.registration?.telephone_1 : "");
				setFormItemValue("patient_alt_phone", data?.registration?.telephone_2 ? data?.registration?.telephone_2 : "");
				setFormItemValue("patient_email", data?.registration?.email ? data?.registration?.email : "");
				// setFormItemValue("patient_vet_status", data?.registration?.first_name ? data?.registration?.first_name : "");
				setFormItemValue("patient_vet_knumber", data?.registration?.knumber ? data?.registration?.knumber : "");
				// setFormItemValue("patient_primary_address_type", "Private Home");
				setFormItemValue("patient_primary_address_street", data?.registration?.street_1 ? data?.registration?.street_1 : "");
				setFormItemValue("patient_primary_address_unit", data?.registration?.street_2 ? data?.registration?.street_2 : "");
				setFormItemValue("patient_primary_address_city", data?.registration?.city ? data?.registration?.city : "");
				setFormItemValue("patient_primary_address_province", data?.registration?.province ? data?.registration?.province : "");
				setFormItemValue("patient_primary_address_postal", data?.registration?.postal_code ? data?.registration?.postal_code : "");

				const mailingSameAsPrimary = data?.registration?.mailing_postal_code == "" || data?.registration?.mailing_postal_code == data?.registration?.postal_code;
				setFormItemValue("patient_mailing_address_same_as_primary", mailingSameAsPrimary);

				if (!mailingSameAsPrimary) {
					// setFormItemValue("patient_mailing_address_po_box", data?.registration?.first_name ? data?.registration?.first_name : "");
					setFormItemValue("patient_mailing_address_street", data?.registration?.mailing_street_1 ? data?.registration?.mailing_street_1 : "");
					setFormItemValue("patient_mailing_address_unit", data?.registration?.mailing_street_2 ? data?.registration?.mailing_street_2 : "");
					setFormItemValue("patient_mailing_address_city", data?.registration?.mailing_city ? data?.registration?.mailing_city : "");
					setFormItemValue("patient_mailing_address_province", data?.registration?.mailing_province ? data?.registration?.mailing_province : "");
					setFormItemValue("patient_mailing_address_postal", data?.registration?.mailing_postal_code ? data?.registration?.mailing_postal_code : "");
				}

				setHasCurrentReg(true);
			}
		} catch (error: any) {
			console.log(error)
			setIsLoading(false);
			setHasCurrentReg(false);
			setShowErrorModal(true);
			setErrorMessage(
				error?.response?.data ||
				"There was an error, please try again later or contact support at ClientCare@noblegrowthcorp.com."
			);
		}

	};
	const handleChangeInput = (e: any) => {
		const fieldName = e.target.name;
		let fieldValue = e.target.value;
		if (fieldName === "patient_main_phone" || fieldName === "patient_alt_phone") {
			fieldValue = phoneFormat(e.target.value);
		}
		if (fieldName === "patient_primary_address_postal" || fieldName === "patient_mailing_address_postal") {
			fieldValue = postalFormat(e.target.value);
		}
		if (fieldName === "patient_date_of_birth") {
			fieldValue = dateFormat(e.target.value);
		}
		if (fieldName === "client_id") {
			fieldValue = clientIdFormat(e.target.value);
		}
		if (fieldName === "patient_mailing_address_same_as_primary") {
			fieldValue = e.target.checked;
		}
		if (fieldName === "attestation_accepted") {
			fieldValue = e.target.checked;
		}


		// console.log(fieldName)
		// console.log(fieldValue)
		// console.log(e.target.checked)

		setFormData((prevState) => ({
			...prevState,
			[fieldName]: fieldValue,
		}));
		setFormFieldErrors((prevState) => ({
			...prevState,
			[fieldName]: validateFormField(fieldName, fieldValue),
		}));


		if (fieldName === "patient_mailing_address_same_as_primary") {

			if (fieldValue === true) {
				setFormItemValue("patient_mailing_address_po_box", "");
				setFormItemValue("patient_mailing_address_street", "");
				setFormItemValue("patient_mailing_address_unit", "");
				setFormItemValue("patient_mailing_address_city", "");
				setFormItemValue("patient_mailing_address_province", "");
				setFormItemValue("patient_mailing_address_postal", "");
			}
		}
	};
	const phoneFormat = (input: any) => {
		if (!input) return input;
		const phoneNumber = input.replace(/[^\d]/g, "");
		const phoneNumberLength = phoneNumber.length;
		if (phoneNumberLength < 4) return phoneNumber;
		if (phoneNumberLength < 7) {
			return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
		}
		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6, 10)}`;
	};
	const postalFormat = (input: any) => {
		if (!input) return input;
		const postalCode = input.toUpperCase().replace(/\W/g, '');
		const postalCodeLength = postalCode.length;
		if (postalCodeLength < 4) return postalCode;
		if (postalCodeLength < 7) {
			return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
		}
		return `${postalCode.slice(0, 3)} ${postalCode.slice(3, 6)}`;
	};
	const dateFormat = (input: any) => {
		if (!input) return input;
		const date = input.replace(/[^\d]/g, "");
		const dateLength = date.length;
		if (dateLength < 5) return date;
		if (dateLength < 7) {
			return `${date.slice(0, 4)}-${date.slice(4)}`;
		}
		return `${date.slice(0, 4)}-${date.slice(
			4,
			6
		)}-${date.slice(6, 8)}`;
	};
	const clientIdFormat = (input: any) => {
		if (!input) return input;
		const client_id = input.replace(/[^\d]/g, "");
		const clientIdLength = client_id.length;
		if (clientIdLength < 5) return client_id;
		if (clientIdLength < 9) {
			return `${client_id.slice(0, 4)}-${client_id.slice(4)}`;
		}
		if (clientIdLength < 13) {
			return `${client_id.slice(0, 4)}-${client_id.slice(
				4,
				8
			)}-${client_id.slice(8, 12)}`;
		}
		return `${client_id.slice(0, 4)}-${client_id.slice(
			4,
			8
		)}-${client_id.slice(8, 12)}-${client_id.slice(12, 16)}`;
	};

	const setFormItemValue = (name: string, value: string | boolean) => {
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
		setFormFieldErrors((prevState) => ({
			...prevState,
			[name]: false,
		}));
	};

	return (
		<RegistrationLayout>
			<div className={`${regSubmittedSuccessfully ? "hidden" : ""} grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4`}>
				<div className="xs:col-span-3 lg:col-span-2">
					<h1 className="text-5xl">Patient Registration</h1>
					<div className="mb-[27px] mt-[27px]">
						Welcome to Noble Growth Corp, we're delighted to have
						you onboard! Follow the steps below to create your
						account.
					</div>
					<div>
						<div className="grid w-full grid-cols-1 gap-4 xs:grid-cols-1 mb-[20px]">
							<div>
								<label htmlFor="submitter_type" className="mb-[6px] flex text-left text-sm">Who is completing this application?</label>
								<select
									id="submitter_type"
									name="submitter_type"
									className={`${formFieldErrors.submitter_type
										? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
										: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default appearance-listbox
									`}
									onChange={handleChangeInput}
									defaultValue={""}
									value={formData.submitter_type}
								>
									<option value="">Choose one</option>
									<option value="Applicant">I am submitting for myself.</option>
									<option value="Caregiver">I am submitting on behalf of someone else.</option>
								</select>
							</div>
						</div>
						<div className="grid w-full grid-cols-1 gap-4 xs:grid-cols-1 mb-[20px]">
							<div>
								<label htmlFor="patient_primary_address_type" className="mb-[6px] flex text-left text-sm">What type of location is the Patient's primary address?</label>
								<select
									id="patient_primary_address_type"
									name="patient_primary_address_type"
									className={`${formFieldErrors.patient_primary_address_type
										? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
										: "bg-white outline-none"}
									w-full rounded px-5 py-2 outline-none transition disabled:cursor-default
									`}
									onChange={handleChangeInput}
									defaultValue={""}
									value={formData.patient_primary_address_type}
								>
									<option value="">Choose one</option>
									<option value="Private Home">Private Home</option>
									<option value="Shelter">Shelter</option>
									<option value="Hostel">Hostel</option>
									<option value="Group Home">Group Home</option>
									<option value="Nursing/Care Home">Nursing/Care Home</option>
								</select>
							</div>
						</div>
					</div>
					<div className={`${(formData.submitter_type == "Applicant" && formData.patient_primary_address_type == "Private Home") ||
						(formData.submitter_type == "" && formData.patient_primary_address_type == "Private Home") ||
						(formData.submitter_type == "Applicant" && formData.patient_primary_address_type == "") ||
						(formData.submitter_type == "" && formData.patient_primary_address_type == "")
						? "hidden"
						: ""}`}>
						<div className={`mb-16 mt-10 w-full rounded-lg border border-noble-gold bg-white xs:px-8 xs:py-8 xl:px-30 l:py-40 l:pb-42`}>
							<p className="text-paragraph">
								Registration applications involving institutional addresses or non-applicant caregivers/persons responsible cannot be completed through this portal at this time.
								<br /><br />
								Please complete the fillable PDF version of this application and submit to us via email at <a href="mailto:ClientCare@noblegrowthcorp.com" className="text-noble-gold hover:underline">ClientCare@noblegrowthcorp.com</a>.
								<a href="https://www.noblegrowthcorp.com/wp-content/uploads/2024/04/Registration-Form-QA-021.F4.01-Fillable.pdf" target="_blank">
									<button
										type="button"
										className="btn-primary flex w-full justify-center rounded-lg py-3 mt-[30px]"
										onClick={validateForm}
									>
										<svg fill="#000000" width="30px" height="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" className="mr-[10px]">
											<g fillRule="evenodd">
												<path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z" />
												<path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z" />
											</g>
										</svg>
										Download PDF Application
									</button>
								</a>
								<br />
								<br />
								<hr />
								<br />
								If you haven't already, print out our Medical Document template and have your Physician or Nurse Practitioner complete and submit it to us.
								<br />
								The medical document MUST be filled out and submitted by an approved Health Care Professional.
								<a href="http://www.noblegrowthcorp.com/wp-content/uploads/2024/04/Medical-Document-QA-021.F3.01-Fillable.pdf" target="_blank">
									<button
										type="button"
										className="btn-secondary flex mx-auto w-half justify-center rounded-lg py-3 px-8 mt-[30px]"
										onClick={validateForm}
									>
										<svg fill="#000000" width="30px" height="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" className="mr-[10px]">
											<g fillRule="evenodd">
												<path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z" />
												<path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z" />
											</g>
										</svg>
										Download PDF Medical Document
									</button>
								</a>
							</p>
						</div>
					</div>
					<div className={`${formData.submitter_type != "Applicant" || formData.patient_primary_address_type != "Private Home"
						? "hidden"
						: ""}`}>
						<div>
							<div className="tabs mb-[40px]">
								<label className="mb-[6px] flex text-left text-sm">What type of application would you like to submit?</label>
								<ul className="font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
									<li className="w-full">
										<button onClick={() => { setFormItemValue("registration_type", "New") }} className={`${formData.registration_type == "New"
											? "bg-noble-gold"
											: "bg-white"} 
										inline-block w-full p-4  rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none`} aria-current="page">New Registration</button>
									</li>
									<li className="w-full">
										<button onClick={() => { setFormItemValue("registration_type", "Renewal") }} className={`${formData.registration_type == "Renewal"
											? "bg-noble-gold"
											: "bg-white"} 
										inline-block w-full p-4 focus:ring-4 focus:ring-blue-300 active focus:outline-none`}>Renewal</button>
									</li>
									<li className="w-full">
										<button onClick={() => { setFormItemValue("registration_type", "Amendment") }} className={`${formData.registration_type == "Amendment"
											? "bg-noble-gold"
											: "bg-white"} 
										inline-block w-full p-4 rounded-r-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none`}>Amendment</button>
									</li>
								</ul>
							</div>
						</div>
						<div className={`${(formData.registration_type == "Renewal" || formData.registration_type == "Amendment")
							? ""
							: "hidden"}`}>
							<div className={`mb-16 mt-10 w-full rounded-lg border border-noble-gold bg-white xs:px-8 xs:py-8 xl:px-30 l:py-40 l:pb-42`}>
								<p className="text-paragraph mb-[20px]">Sign-in to load your existing registration information.</p>
								<div className="mb-[20px]">
									<label
										htmlFor="client_id"
										className="mb-[6px] flex text-left text-sm "
									>
										Client ID
									</label>
									<input
										id="client_id"
										name="client_id"
										type="text"
										placeholder="0000-0000-0000-0000"
										disabled={hasCurrentReg}
										className={`${formFieldErrors.client_id
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.client_id}
										onChange={handleChangeInput}
									/>
								</div>
								<div className="mb-[20px]">
									<label
										htmlFor="password"
										className="mb-[6px] flex text-left text-sm "
									>
										Password
									</label>
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password"
										disabled={hasCurrentReg}
										className={`${formFieldErrors.password
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.password}
										onChange={handleChangeInput}
									/>
								</div>

								<button
									className={`${hasCurrentReg
										? "hidden"
										: ""} btn-primary text-noble-dark flex justify-center rounded-lg px-10 py-3`}
									disabled={isLoading || hasCurrentReg}
									onClick={getCurrentRegistration}
								>
									<svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[10px]">
										<g id="Interface / Download">
											<path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke={isLoading || hasCurrentReg ? "#baaba7" : "#000000"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</g>
									</svg>
									Get Current Registration
									{isLoading && (
										<svg
											aria-hidden="true"
											className="text-gray-200 dark:text-gray-600 ml-[10px] mr-2 mt-[5px] inline h-6 w-6 animate-spin fill-blue-600"
											viewBox="0 0 100 101"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
												fill="currentColor"
											/>
											<path
												d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
												fill="currentFill"
											/>
										</svg>
									)}
								</button>
							</div>
						</div>

						<div className={`${formData.registration_type != "New" && !hasCurrentReg
							? "hidden"
							: ""}`}>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mb-[30px] mt-[40px]">
								<hr />
								<h2 className="text-2xl">1. Patient Information</h2>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div>
									<label
										htmlFor="patient_first_name"
										className="mb-[6px] flex text-left text-sm "
									>
										First Name
									</label>
									<input
										id="patient_first_name"
										name="patient_first_name"
										type="text"
										placeholder="FirstName"
										className={`${formFieldErrors.patient_first_name
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_first_name}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_middle_name"
										className="mb-[6px] flex text-left text-sm "
									>
										Middle Name / Initial
									</label>
									<input
										id="patient_middle_name"
										name="patient_middle_name"
										type="text"
										placeholder="MiddleName"
										className={`${formFieldErrors.patient_middle_name
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_middle_name}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_last_name"
										className="mb-[6px] flex text-left text-sm "
									>
										Last Name
									</label>
									<input
										id="patient_last_name"
										name="patient_last_name"
										type="text"
										placeholder="LastName"
										className={`${formFieldErrors.patient_last_name
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_last_name}
										onChange={handleChangeInput}
									/>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-2 mb-[20px]">
								<div>
									<label
										htmlFor="patient_date_of_birth"
										className="mb-[6px] flex text-left text-sm "
									>
										Date of Birth (YYYY-MM-DD)
									</label>
									<input
										id="patient_date_of_birth"
										name="patient_date_of_birth"
										type="text"
										placeholder="1980-01-31"
										className={`${formFieldErrors.patient_date_of_birth
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_date_of_birth}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label htmlFor="patient_gender" className="mb-[6px] flex text-left text-sm">Gender</label>
									<select
										id="patient_gender"
										name="patient_gender"
										className={`${formFieldErrors.patient_gender
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										onChange={handleChangeInput}
										defaultValue={""}
										value={formData.patient_gender}
									>
										<option value="">Choose one</option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
										<option value="Other">Other</option>
										<option value="Undisclosed">Undisclosed</option>
									</select>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div>
									<label
										htmlFor="patient_main_phone"
										className="mb-[6px] flex text-left text-sm "
									>
										Main Phone Number
									</label>
									<input
										id="patient_main_phone"
										name="patient_main_phone"
										type="text"
										placeholder="555-555-1234"
										className={`${formFieldErrors.patient_main_phone
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_main_phone}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_alt_phone"
										className="mb-[6px] flex text-left text-sm "
									>
										Alternative Phone Number
									</label>
									<input
										id="patient_alt_phone"
										name="patient_alt_phone"
										type="text"
										placeholder="555-555-1234"
										className={`${formFieldErrors.patient_alt_phone
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_alt_phone}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_email"
										className="mb-[6px] flex text-left text-sm "
									>
										Email Address
									</label>
									<input
										id="patient_email"
										name="patient_email"
										type="text"
										placeholder="jdoe@email.com"
										className={`${formFieldErrors.patient_email
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_email}
										onChange={handleChangeInput}
									/>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-2 mb-[20px]">
								<div>
									<label htmlFor="patient_vet_status" className="mb-[6px] flex text-left text-sm">Veteran's Service Status</label>
									<select
										id="patient_vet_status"
										name="patient_vet_status"
										className={`${formFieldErrors.patient_vet_status
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										onChange={handleChangeInput}
										defaultValue={""}
										value={formData.patient_vet_status}
									>
										<option value="">Not a Veteran</option>
										<option value="Still Serving">Still Serving</option>
										<option value="Non-Serving">Non-Serving</option>
									</select>
								</div>
								<div>
									<label
										htmlFor="patient_vet_knumber"
										className="mb-[6px] flex text-left text-sm "
									>
										Veteran's K-Number
									</label>
									<div className="relative mb-4 flex flex-wrap items-stretch">
										<span
											className="input-prepend flex items-center rounded outline-none px-2 py-[0.25rem]"
											id="basic-addon1"
										>K -</span>
										<input
											id="patient_vet_knumber"
											name="patient_vet_knumber"
											type="text"
											placeholder="1234567"
											className={`${formFieldErrors.patient_vet_knumber
												? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
												: "bg-white outline-none"}
									rounded flex-auto px-5 py-2 outline-none transition disabled:cursor-default
									`}
											value={formData.patient_vet_knumber}
											onChange={handleChangeInput}
										/>
									</div>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mt-[40px] mb-[20px]">
								<hr />
								<h2 className="text-2xl">2. Address Information</h2>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mb-[10px]">
								<h3 className="text-l">Primary Residence Address</h3>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div className="col-span-2">
									<label
										htmlFor="patient_primary_address_street"
										className="mb-[6px] flex text-left text-sm "
									>
										Street Address
									</label>
									<input
										id="patient_primary_address_street"
										name="patient_primary_address_street"
										type="text"
										placeholder="123 Main Street"
										className={`${formFieldErrors.patient_primary_address_street
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_primary_address_street}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_primary_address_unit"
										className="mb-[6px] flex text-left text-sm "
									>
										Unit Number
									</label>
									<input
										id="patient_primary_address_unit"
										name="patient_primary_address_unit"
										type="text"
										placeholder="1A"
										className={`${formFieldErrors.patient_primary_address_unit
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_primary_address_unit}
										onChange={handleChangeInput}
									/>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div>
									<label
										htmlFor="patient_primary_address_city"
										className="mb-[6px] flex text-left text-sm "
									>
										City, Town, or Village
									</label>
									<input
										id="patient_primary_address_city"
										name="patient_primary_address_city"
										type="text"
										placeholder="Springfield"
										className={`${formFieldErrors.patient_primary_address_city
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_primary_address_city}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_primary_address_province"
										className="mb-[6px] flex text-left text-sm "
									>
										Province or Territory
									</label>
									<select
										id="patient_primary_address_province"
										name="patient_primary_address_province"
										className={`${formFieldErrors.patient_primary_address_province
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										onChange={handleChangeInput}
										defaultValue={""}
										value={formData.patient_primary_address_province}
									>
										<option value="">Choose one</option>
										<option value="AB">Alberta</option>
										<option value="BC">British Columbia</option>
										<option value="MB">Manitoba</option>
										<option value="NB">New Brunswick</option>
										<option value="NL">Newfoundland & Labrador</option>
										<option value="NS">Nova Scotia</option>
										<option value="NT">Northwest Territories</option>
										<option value="NU">Nunavut</option>
										<option value="ON">Ontario</option>
										<option value="PE">Prince Edward Island</option>
										<option value="QC">Quebec</option>
										<option value="SK">Saskatchewan</option>
										<option value="YT">Yukon</option>
									</select>
								</div>
								<div>
									<label
										htmlFor="patient_primary_address_postal"
										className="mb-[6px] flex text-left text-sm "
									>
										Postal Code
									</label>
									<input
										id="patient_primary_address_postal"
										name="patient_primary_address_postal"
										type="text"
										placeholder="H0H 0H0"
										className={`${formFieldErrors.patient_primary_address_postal
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
									`}
										value={formData.patient_primary_address_postal}
										onChange={handleChangeInput}
									/>
								</div>
							</div>

							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mb-[10px]">
								<h3 className="text-l">Mailing Address</h3>
							</div>

							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mb-[20px]">
								<div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
									<input
										className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] accent-black focus:ring-transparent"
										type="checkbox"
										id="patient_mailing_address_same_as_primary"
										name="patient_mailing_address_same_as_primary"
										value="true"
										checked={formData.patient_mailing_address_same_as_primary === true}
										onChange={handleChangeInput}
									/>
									<label htmlFor="patient_mailing_address_same_as_primary" className="inline-block text-base font-normal hover:cursor-pointer">
										<div className="text-gray-100 text-s">
											Mailing address is the same as Primary address.
										</div>
									</label>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div>
									<label
										htmlFor="patient_mailing_address_po_box"
										className="mb-[6px] flex text-left text-sm "
									>
										P.O. Box Number
									</label>
									<input
										id="patient_mailing_address_po_box"
										name="patient_mailing_address_po_box"
										type="text"
										placeholder="PO Box 123 STN A"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_po_box
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.patient_mailing_address_po_box}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_mailing_address_street"
										className="mb-[6px] flex text-left text-sm "
									>
										Street Address
									</label>
									<input
										id="patient_mailing_address_street"
										name="patient_mailing_address_street"
										type="text"
										placeholder="123 Main Street"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_street
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.patient_mailing_address_street}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_mailing_address_unit"
										className="mb-[6px] flex text-left text-sm "
									>
										Unit Number
									</label>
									<input
										id="patient_mailing_address_unit"
										name="patient_mailing_address_unit"
										type="text"
										placeholder="1A"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_unit
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.patient_mailing_address_unit}
										onChange={handleChangeInput}
									/>
								</div>
							</div>
							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-3 mb-[20px]">
								<div>
									<label
										htmlFor="patient_mailing_address_city"
										className="mb-[6px] flex text-left text-sm "
									>
										City, Town, or Village
									</label>
									<input
										id="patient_mailing_address_city"
										name="patient_mailing_address_city"
										type="text"
										placeholder="Springfield"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_city
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.patient_mailing_address_city}
										onChange={handleChangeInput}
									/>
								</div>
								<div>
									<label
										htmlFor="patient_mailing_address_province"
										className="mb-[6px] flex text-left text-sm "
									>
										Province or Territory
									</label>
									<select
										id="patient_mailing_address_province"
										name="patient_mailing_address_province"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_province
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										onChange={handleChangeInput}
										defaultValue={""}
										value={formData.patient_mailing_address_province}
									>
										<option value="">Choose one</option>
										<option value="AB">Alberta</option>
										<option value="BC">British Columbia</option>
										<option value="MB">Manitoba</option>
										<option value="NB">New Brunswick</option>
										<option value="NL">Newfoundland & Labrador</option>
										<option value="NS">Nova Scotia</option>
										<option value="NT">Northwest Territories</option>
										<option value="NU">Nunavut</option>
										<option value="ON">Ontario</option>
										<option value="PE">Prince Edward Island</option>
										<option value="QC">Quebec</option>
										<option value="SK">Saskatchewan</option>
										<option value="YT">Yukon</option>
									</select>
								</div>
								<div>
									<label
										htmlFor="patient_mailing_address_postal"
										className="mb-[6px] flex text-left text-sm "
									>
										Postal Code
									</label>
									<input
										id="patient_mailing_address_postal"
										name="patient_mailing_address_postal"
										type="text"
										placeholder="H0H 0H0"
										disabled={formData.patient_mailing_address_same_as_primary === true}
										className={`${formFieldErrors.patient_mailing_address_postal
											? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
											: "bg-white outline-none"}
									w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:bg-gray
									`}
										value={formData.patient_mailing_address_postal}
										onChange={handleChangeInput}
									/>
								</div>
							</div>

							<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-1 mb-[30px] mt-[40px]">
								<hr />
								<h2 className="text-2xl">3. Patient Attestation</h2>
							</div>

							<div className="grid w-full grid-cols-2 gap-4 xs:grid-cols-1 ">
								<div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
									<input
										className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] accent-black focus:ring-transparent"
										type="checkbox"
										id="attestation_accepted"
										name="attestation_accepted"
										value="true"
										onChange={handleChangeInput}
									/>
									<label htmlFor="attestation_accepted" className="inline-block text-base font-normal hover:cursor-pointer">
										<div className="text-gray-100">
											I, the party completing this registration, hereby confirm the following:
											<ul className="ml-6 list-disc">
												<li>I reside in Canada.</li>
												<li>The information in the application is correct and complete.</li>
												<li>The medical document that forms the basis for the application has not, to my knowledge, been altered in any way.</li>
												<li>The medical document is not being used to seek or obtain cannabis products from another source.</li>
												<li>I intend to use any cannabis product that is supplied to me on the basis of the application only for my own medical purposes.</li>
												{/* <li>I am responsible for the applicant (in the case of a caregiver / person responsible). </li> */}
											</ul>
										</div>
									</label>
								</div>
								<div>
									<button
										type="submit"
										className="btn-primary flex w-full justify-center rounded-lg py-3"
										disabled={!formData.attestation_accepted || isLoading}
										onClick={validateForm}
									>
										Submit
										{isLoading && (
											<svg
												aria-hidden="true"
												className="text-gray-200 dark:text-gray-600 ml-[5px] mr-2 mt-[5px] inline h-6 w-6 animate-spin fill-blue-600"
												viewBox="0 0 100 101"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
													fill="currentColor"
												/>
												<path
													d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
													fill="currentFill"
												/>
											</svg>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div>
						<br />
						<hr />
						<br />
						<a href="https://shop.noblegrowthcorp.com" className="text-noble-dark text-lg font-bold hover:underline underline-offset-4">Already a Member? Sign-in.</a>
					</div>
				</div>
			</div>
			<div className={`${regSubmittedSuccessfully ? "" : "hidden"} grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4`}>
				<div className="xs:col-span-3 lg:col-span-2">
					<h1 className="text-5xl">Success!</h1>
					<div className="mb-[27px] mt-[27px]">
						Thanks for submitting your application! Please keep an eye on your inbox for information on your application.
					</div>
					<div className="mb-[27px] mt-[27px]">
						If you have any questions or concerns, don't hesitate to contact us at{" "}
						<a
							href="mailto:ClientCare@noblegrowthcorp.com"
							className="text-primary hover:underline"
						>
							ClientCare@noblegrowthcorp.com
						</a>
					</div>
					<div className="mb-[27px] mt-[27px]">
						<hr />
						<br />
						<p className=" text-paragraph">
							If you haven't already, book an appointment with Sentry Health Clinic to get your medical document.
							<a href="https://rx.clinic/noble" target="_blank">
								<button
									type="button"
									className="btn-primary flex w-full justify-center rounded-lg py-3 mt-[30px]"
									onClick={validateForm}
								>
									<svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M17 4.75H15.75V3.5C15.75 3.30109 15.671 3.11032 15.5303 2.96967C15.3897 2.82902 15.1989 2.75 15 2.75C14.8011 2.75 14.6103 2.82902 14.4697 2.96967C14.329 3.11032 14.25 3.30109 14.25 3.5V4.75H9.75V3.5C9.75 3.30109 9.67098 3.11032 9.53033 2.96967C9.38968 2.82902 9.19891 2.75 9 2.75C8.80109 2.75 8.61032 2.82902 8.46967 2.96967C8.32902 3.11032 8.25 3.30109 8.25 3.5V4.75H7C6.27065 4.75 5.57118 5.03973 5.05546 5.55546C4.53973 6.07118 4.25 6.77065 4.25 7.5V18.5C4.25 19.2293 4.53973 19.9288 5.05546 20.4445C5.57118 20.9603 6.27065 21.25 7 21.25H17C17.7293 21.25 18.4288 20.9603 18.9445 20.4445C19.4603 19.9288 19.75 19.2293 19.75 18.5V7.5C19.75 6.77065 19.4603 6.07118 18.9445 5.55546C18.4288 5.03973 17.7293 4.75 17 4.75ZM7 6.25H8.25V7.5C8.25 7.69891 8.32902 7.88968 8.46967 8.03033C8.61032 8.17098 8.80109 8.25 9 8.25C9.19891 8.25 9.38968 8.17098 9.53033 8.03033C9.67098 7.88968 9.75 7.69891 9.75 7.5V6.25H14.25V7.5C14.25 7.69891 14.329 7.88968 14.4697 8.03033C14.6103 8.17098 14.8011 8.25 15 8.25C15.1989 8.25 15.3897 8.17098 15.5303 8.03033C15.671 7.88968 15.75 7.69891 15.75 7.5V6.25H17C17.3315 6.25 17.6495 6.3817 17.8839 6.61612C18.1183 6.85054 18.25 7.16848 18.25 7.5V10.25H5.75V7.5C5.75 7.16848 5.8817 6.85054 6.11612 6.61612C6.35054 6.3817 6.66848 6.25 7 6.25ZM17 19.75H7C6.66848 19.75 6.35054 19.6183 6.11612 19.3839C5.8817 19.1495 5.75 18.8315 5.75 18.5V11.75H18.25V18.5C18.25 18.8315 18.1183 19.1495 17.8839 19.3839C17.6495 19.6183 17.3315 19.75 17 19.75Z" fill="#000000" />
										<path d="M14 15.25H12.75V14C12.75 13.8011 12.671 13.6103 12.5303 13.4697C12.3897 13.329 12.1989 13.25 12 13.25C11.8011 13.25 11.6103 13.329 11.4697 13.4697C11.329 13.6103 11.25 13.8011 11.25 14V15.25H10C9.80109 15.25 9.61032 15.329 9.46967 15.4697C9.32902 15.6103 9.25 15.8011 9.25 16C9.25 16.1989 9.32902 16.3897 9.46967 16.5303C9.61032 16.671 9.80109 16.75 10 16.75H11.25V18C11.25 18.1989 11.329 18.3897 11.4697 18.5303C11.6103 18.671 11.8011 18.75 12 18.75C12.1989 18.75 12.3897 18.671 12.5303 18.5303C12.671 18.3897 12.75 18.1989 12.75 18V16.75H14C14.1989 16.75 14.3897 16.671 14.5303 16.5303C14.671 16.3897 14.75 16.1989 14.75 16C14.75 15.8011 14.671 15.6103 14.5303 15.4697C14.3897 15.329 14.1989 15.25 14 15.25Z" fill="#000000" />
									</svg>
									Book an Appointment with Sentry Health
								</button>
							</a>
						</p>
					</div>
					<div className="mb-[27px] mt-[27px]">
						<hr />
						<br />
						<p className=" text-paragraph">
							Alternatively, print out our Medical Document template and have your Physician or Nurse Practitioner complete and submit it to us.
							<br />
							<br />
							The medical document MUST be filled out and submitted by an approved Health Care Professional.
							<a href="http://www.noblegrowthcorp.com/wp-content/uploads/2024/04/Medical-Document-QA-021.F3.01-Fillable.pdf" target="_blank">
								<button
									type="button"
									className="btn-noble-light text-[1.1em] flex w-[75%] justify-center border rounded-lg py-2 mt-[30px] mx-auto"
									onClick={validateForm}
								>
									<svg fill="#000000" width="25px" height="25px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" className="mr-[10px]">
										<g fillRule="evenodd">
											<path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z" />
											<path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z" />
										</g>
									</svg>
									Download PDF Medical Document
								</button>
							</a>
						</p>
					</div>
				</div>
			</div>
			{
				showErrorModal && (
					<ErrorModal
						setModalOpen={setShowErrorModal}
						message={errorMessage}
					/>
				)
			}
		</RegistrationLayout >
	);
};

export default RegistrationForm;
