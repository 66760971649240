// import { AiOutlineInstagram, AiOutlineLinkedin } from "react-icons/ai";
// import { FiTwitter } from "react-icons/fi";
// import { RiFacebookCircleLine } from "react-icons/ri";

const Footer = () => {
	return (
		<footer className="footer flex h-[7rem] flex-col items-center justify-between px-4 py-4 text-white md:px-6 lg:flex-row lg:py-0 2xl:px-12">
			<div className="flex flex-col gap-2 md:flex-row md:gap-8">
				<p className="hidden md:block">
					Copyright © Noble Growth Corp. 2023
				</p>
				<ul className="flex gap-6 text-[#DED4FF]">
					<li>
						<a
							className="break-words underline decoration-noble-gold text-noble-gold underline-offset-4"
							href="https://www.noblegrowthcorp.com/terms/"
							target="_blank"
						>
							Terms & Conditions
						</a>
					</li>
					<li>
						<a
							className="break-words underline decoration-noble-gold text-noble-gold underline-offset-4"
							href="https://www.noblegrowthcorp.com/privacy-policy/"
							target="_blank"
						>
							Privacy Policy
						</a>
					</li>
					{/* <li>
						<a
							className="break-words underline decoration-noble-gold text-noble-gold underline-offset-4"
							href="https://www.noblegrowthcorp.com/wp-content/uploads/2024/04/Registration-Form-QA-021.F4.01-Fillable.pdf"
							target="_blank"
						>
							Registration Form (Fillable PDF)
						</a>
					</li>
					<li>
						<a
							className="break-words underline decoration-noble-gold text-noble-gold underline-offset-4"
							href="https://www.noblegrowthcorp.com/wp-content/uploads/2024/04/Medical-Document-QA-021.F3.01-Fillable.pdf"
							target="_blank"
						>
							Medical Document (Fillable PDF)
						</a>
					</li> */}
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
