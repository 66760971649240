import type { ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";

interface RegistrationLayoutProps {
	children: ReactNode;
}

const RegistrationLayout = ({ children }: RegistrationLayoutProps) => {
	return (
		<div id="wrapper">
			<Header />
			<div className="main-content relative flex bg-sidebar-bk">
				<aside
					className={`'translate-x-0' invisible absolute left-0 top-0 z-30 flex h-full w-[360px] flex-col bg-white duration-300 ease-linear lg:visible  lg:static lg:z-10 lg:translate-x-0`}
				>
					<div className="no-scrollbar sticky top-20 flex flex-col overflow-y-auto duration-300 ease-linear"></div>
				</aside>
				<main className="flex flex-1 bg-noble-light pb-24 pl-8 pr-4 pt-14 md:pl-10 md:pr-6 2xl:pl-20 2xl:pr-11">
					{children}
				</main>
			</div>
			<Footer />
		</div>
	);
};

export default RegistrationLayout;
