import Modal from "../Modal";

interface ErrorModalProps {
	setModalOpen: (open: boolean) => void;
	message: string;
}
const ErrorModal = ({ setModalOpen, message }: ErrorModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			children={
				<div className="flex flex-col	">
					<h2 className="mb-5	text-3xl text-paragraph">Error</h2>
					<p className="text-paragraph">{message}</p>

					<div className="mt-auto">
						<div className="ml-auto flex w-6/12 justify-end">
							<button
								type="submit"
								className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm mt-[40px]"
								onClick={() => setModalOpen(false)}
							>
								Back
							</button>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ErrorModal;
