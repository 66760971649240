import axios from "axios";

import { API_URL } from "./constants";

export const getRegistration = async (client_id: string, password: string) => {
	// try {
	const res = await axios.get(API_URL + "/get-current-registration", {
		params: {
			client_id: client_id,
			password: password
		}
	});
	return res.data;
	// } catch (error) {
	// 	// console.log(error);
	// }
};

export const newRegistration = async (params: any) => {
	const res = await axios.post(API_URL + "/new-registration", {
		...params
	});
	return res;
};

export const amendRegistration = async (params: any) => {
	const res = await axios.post(API_URL + "/amend-registration", {
		...params
	});
	return res;
};

export const renewRegistration = async (params: any) => {
	const res = await axios.post(API_URL + "/renew-registration", {
		...params
	});
	return res;
};
